import React from "react"
import PropTypes from "prop-types"
import Octicon, { LinkExternal } from "@primer/octicons-react"
import "./outbound.css"

const Outbound = React.memo(({ url }) =>
  <span className='outbound'>
    <a href={url} target="_blank" rel="noopener noreferrer">
      <Octicon icon={LinkExternal} verticalAlign='text-top'/>
    </a>
  </span>,
)

Outbound.propTypes = {
  url: PropTypes.string.isRequired,
}

export default Outbound
